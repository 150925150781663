import PhoneModel from "@/models/PhoneModel";
import EmailModel from "@/models/EmailModel";
import ManagerModel from "@/components/shared/managerInput/ManagerModel";
import { decodeHTMLEntities } from "@/utils/stringUtils";

class AccountEditModel {
  constructor() {
    this.objRaw = {};

    this.corporateNum = "";
    this.loginPass = "";
    this.companyName = "";
    this.ceo = "";
    this.business = "";
    this.businessType = "";
    this.address = "";
    this.address2 = "";
    this.zipcode = "";
    this.tel = new PhoneModel();
    this.fax = new PhoneModel();
    this.email = new EmailModel();
    this.bankDepositor = "";
    this.bankCode = "";
    this.bankNumber = "";
    this.siteUrl = "";
    this.attachFileList = [];

    this.businessRegFileList = [];
    this.bankBookFileList = [];

    this.managerInfo = {
      dataList: [],
    };
  }

  setData(obj) {
    this.objRaw = obj;

    if (obj.corporateNum) this.corporateNum = obj.corporateNum;
    if (obj.companyName) this.companyName = decodeHTMLEntities(document, obj.companyName);
    if (obj.ceo) this.ceo = obj.ceo;

    if (obj.business) this.business = decodeHTMLEntities(document, obj.business);
    if (obj.businessType) this.businessType = decodeHTMLEntities(document, obj.businessType);

    if (obj.address) this.address = obj.address;
    if (obj.address2) this.address2 = decodeHTMLEntities(document, obj.address2);
    if (obj.zipcode) this.zipcode = obj.zipcode;

    if (obj.tel) this.tel.setDataByString(obj.tel);
    if (obj.fax) this.fax.setDataByString(obj.fax);
    if (obj.email) this.email.setDataByString(obj.email);
    if (obj.siteUrl) this.siteUrl = obj.siteUrl;
    if (obj.bankDepositor) this.bankDepositor = obj.bankDepositor;
    if (obj.bankCode) this.bankCode = obj.bankCode;
    if (obj.bankNumber) this.bankNumber = obj.bankNumber;

    if (obj.attachFileList) this.attachFileList = obj.attachFileList;

    if (obj.businessRegFile) {
      this.businessRegFileList = [obj.businessRegFile];
    }
    if (obj.bankBookFile) {
      this.bankBookFileList = [obj.bankBookFile];
    }

    if (obj.partnerPersonList) {
      // this.partnerPersonList = obj.partnerPersonList.map( item => {
      this.managerInfo.dataList = obj.partnerPersonList.map((item) => {
        const model = new ManagerModel();

        model.setDataFromServer(item);

        return model;
      });
    }
  }

  getData() {
    let obj = {
      zipcode: this.zipcode,

      companyName: this.companyName,
      ceo: this.ceo,
      business: this.business,
      businessType: this.businessType,
      address: this.address,
      address2: this.address2,
      siteUrl: this.siteUrl,
      bankDepositor: this.bankDepositor,
      bankCode: this.bankCode,
      bankNumber: this.bankNumber,
      attachFileList: this.attachFileList,
    };

    if (this.businessRegFileList.length > 0) {
      obj.businessRegFile = this.businessRegFileList[0];
    }
    if (this.bankBookFileList.length > 0) {
      obj.bankBookFile = this.bankBookFileList[0];
    }

    if (this.tel.getIsValid()) {
      obj.tel = this.tel.getTextFull();
    }
    if (this.fax.getIsValid()) {
      obj.fax = this.fax.getTextFull();
    }
    if (this.email.getIsValid()) {
      obj.email = this.email.getTextFull();
    }

    obj.partnerPersonList = this.managerInfo.dataList.map((item) => {
      return item.getDataForServer();
    });

    obj = Object.assign({}, this.objRaw, obj);

    obj.loginPass = this.loginPass;

    return obj;
  }

  getManagerInfoAlertMessage() {
    const dataList = this.managerInfo.dataList;

    var alertMessage = null;

    for (var i = 0; i < dataList.length; i++) {
      const item = dataList[i];

      alertMessage = item.getAlertMessage();

      if (alertMessage) break;
    }

    return alertMessage;
  }

  getAlertMessage() {
    if (!this.companyName) return "상호를 입력해주세요.";
    if (!this.ceo) return "대표자를 입력해주세요.";
    if (!this.tel.getIsValid()) return "전화번호를 입력해주세요.";
    if (!this.email.getIsValid()) return "이메일을 입력해주세요.";
    if (!this.business) return "업태를 입력해주세요.";
    if (!this.businessType) return "업종을 입력해주세요.";

    if (!this.address) return "주소를 확인해주세요.";
    //if (!this.address2) return '주소를 확인해주세요.';
    //if (!this.zipcode) return '주소를 확인해주세요.';

    // if( this.attachFileList.length < 1 ) return '첨부 파일을 확인해주세요.';
    //if (this.businessRegFileList.length < 1) return '사업자등록증을 확인해주세요.';
    if (!this.bankDepositor) return "예금주를 확인해주세요.";
    if (!Number(this.bankCode) > 0) return "은행은 확인해주세요";
    if (!this.bankNumber) return "계좌번호를 확인해주세요";

    const alertMessageAboutManager = this.getManagerInfoAlertMessage();
    if (alertMessageAboutManager) return alertMessageAboutManager;

    // const isManagerListValid = this.managerInfo.dataList.every( item => item.getIsValid() );
    // if( !isManagerListValid ) return '담당자 정보를 확인해주세요.'

    return null;
  }
}

export default AccountEditModel;
