<template>
  <TableViewWithTitle title="내 정보">
    <template v-slot:headLeft>
      <!-- <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r"></span>정보 수정 시 계정의 법인 정보에 동일하게
        반영됩니다.
      </p> -->
    </template>
    <template v-slot:body>
      <tr>
        <th>ID (사업자 번호)</th>
        <td colspan="3">
          {{ model.corporateNum }}
        </td>
      </tr>
      <tr>
        <th>비밀번호</th>
        <td colspan="3">
          <button type="button" class="btn_fourthly btn_small" @click="onClickPassEdit">
            비밀번호 변경
          </button>
        </td>
      </tr>
      <tr v-if="isPassEdit">
        <th>새 비밀번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <div class="group_form">
            <Input
              type="password"
              class="w208"
              :maxLength="20"
              placeholder="영문/숫자/특수문자 포함 20자 이내"
              :value.sync="model.loginPass"
            />
            <p :class="['txt_view_r', model.loginPass && !isPassFormat ? 'tc_red' : 'tc_b']">
              * 영문과 숫자를 조합하여 8자 이상 20자 이내로 작성 (특수 문자 입력 포함)
            </p>
          </div>
        </td>
      </tr>
      <tr v-if="isPassEdit">
        <th>새 비밀번호 확인<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <div class="group_form">
            <Input
              type="password"
              class="w208"
              :maxLength="12"
              placeholder="비밀번호 확인"
              :value.sync="loginPassConfirm"
            />
            <p class="txt_view_r tc_b">* 입력된 비밀번호와 동일하게 작성</p>
            <p v-if="loginPassConfirm && !isPassContirm" class="txt_view_r tc_red">
              * 비밀번호가 일치하지 않습니다.
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <th>상호<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="model.companyName" />
        </td>
        <th>대표자<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="model.ceo" />
        </td>
      </tr>
      <tr>
        <th>전화번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <InputPhone
            :useMobile="true"
            :useArea="true"
            :phone0.sync="model.tel.phone0"
            :phone1.sync="model.tel.phone1"
            :phone2.sync="model.tel.phone2"
          />
        </td>
        <th>FAX</th>
        <td>
          <InputPhone
            :useMobile="false"
            :useArea="true"
            :phone0.sync="model.fax.phone0"
            :phone1.sync="model.fax.phone1"
            :phone2.sync="model.fax.phone2"
          />
        </td>
      </tr>
      <tr>
        <th>주소<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputAddress
            :zipcode.sync="model.zipcode"
            :address.sync="model.address"
            :address2.sync="model.address2"
          />
          <!-- <div class="group_form">
            <Input
              class="w152" />
            <button type="button" class="btn_fourthly btn_small">우편번호 검색</button>
          </div>
          <div class="group_form">
            <Input class="w380" :value.sync="model.address" />
            <Input class="w380" :value.sync="model.address2" />
          </div> -->
        </td>
      </tr>
      <tr>
        <th>대표 이메일<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputEmail
            :emailName.sync="model.email.name"
            :emailDomain.sync="model.email.domain"
            :isMailPushBtn="false"
            @onClickMailConfirm="onClickMailConfirm"
          />
        </td>
      </tr>
      <tr>
        <th>업태<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="model.business" />
        </td>
        <th>업종<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input :value.sync="model.businessType" />
        </td>
      </tr>
      <tr>
        <th>사업자등록증</th>
        <td colspan="3">
          <FileWrite
            :canMultiple="false"
            :dataList="model.businessRegFileList"
            @fileUploaded="(file) => (model.businessRegFileList = file)"
            @fileRemoved="(fileToRemove) => (model.businessRegFileList = [])"
          />
        </td>
      </tr>
      <tr>
        <th>계좌번호</th>
        <td class="align_top">
          예금주) {{ model.bankDepositor }}<br />
          {{ computedBankName }}-{{ model.bankNumber }}
          <!-- <InputBank
            :bankDepositor.sync="model.bankDepositor"
            :bankCode.sync="model.bankCode"
            :bankNumber.sync="model.bankNumber" 
          />-->
        </td>
        <th>통장사본</th>
        <td>
          <FileView :dataList="model.bankBookFileList || []" />
          <!-- <FileWrite
            :canMultiple="false"
            :dataList="model.bankBookFileList"
            :invalidFileExtension="invalidFileExtensions"
            @fileUploaded="(file) => (model.bankBookFileList = file)"
            @fileRemoved="(fileToRemove) => (model.bankBookFileList = [])"
          /> -->
        </td>
      </tr>
      <tr>
        <th>첨부 파일</th>
        <!-- 필수 항목에서 제외 -->
        <td colspan="3">
          <FileWrite
            :dataList="model.attachFileList"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
          <span>
          ※ 필수 첨부 파일<br/>
          회사소개서 (개요, 조직인원현황, 사업범위, 주요 레퍼런스, 제품소개 등), 재무제표 (직전 2개년), 신용평가서, 공사면허 (공사계약시 첨부)          
          </span>          
        </td>
      </tr>
      <tr>
        <th>참고 URL</th>
        <td colspan="3">
          <Input
            :maxLength="MAX_LENGTH_URL"
            placeholder="회사 홈페이지 주소을 입력하세요"
            :value.sync="model.siteUrl"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import InputEmail from "@/components/common/input/InputEmail";
import InputPhone from "@/components/common/input/InputPhone";
import InputBank from "@/components/common/input/InputBank";
import FileWrite from "@/components/common/file/FileWrite";
import FileView from "@/components/common/file/FileView";
import InputAddress from "@/components/common/input/InputAddress";

import AccountEditModel from "./AccountEditModel";

import { file } from "@/constants/options";
import { MAX_LENGTH_URL } from "@/constants/commonTextLength";
import { COMMON_CODE_ACTION } from "@/store/modules/common/action";
import { mapState } from "vuex";

export default {
  name: "MyInfo",
  components: {
    TableViewWithTitle,
    Input,
    InputEmail,
    InputPhone,
    InputBank,
    FileWrite,
    FileView,
    InputAddress,
  },
  props: {
    myId: String,
    model: AccountEditModel,
  },
  data() {
    return {
      isPassEdit: false,
      loginPassConfirm: "",
    };
  },
  computed: {
    ...mapState({
      bankCodeList: (state) => state.commonCode.bankCodeList,
    }),
    computedBankName() {
      const bankObj = this.bankCodeList.find((item) => this.model.bankCode === item.code);

      return bankObj?.name;
    },
    invalidFileExtensions() {
      return file.INVALID_FILE_EXTENSIONS;
    },
    isPassFormat() {
      const str = this.model.loginPass;

      // 입력 안하면 패스 실패.
      if (!str) return false;

      var reg1 = /^[a-z0-9]{8,12}$/;
      var reg2 = /[a-z]/g;
      var reg3 = /[0-9]/g;

      //return reg1.test(str) && reg2.test(str) && reg3.test(str);
      return true;

      // if(str != ''){
      //   return(reg1.test(str) &&  reg2.test(str) && reg3.test(str));
      // }else{
      //   return true
      // }
    },
    isPassContirm() {
      if (!this.loginPassConfirm) return false;

      return this.model.loginPass === this.loginPassConfirm;

      // if(this.model.loginPass != '' && this.loginPassConfirm != ''){
      //   return this.model.loginPass === this.loginPassConfirm
      // }else{
      //   return true
      // }
    },
    MAX_LENGTH_URL() {
      return MAX_LENGTH_URL;
    },
  },
  created() {
    const { GET_BANK_CODE } = COMMON_CODE_ACTION;
    this.$store.dispatch(GET_BANK_CODE);
  },
  methods: {
    onClickMailConfirm() {
      this.$emit("onClickMailConfirm");
    },
    getAlertMessageAboutPassword() {
      if (!this.isPassEdit) return null;

      if (!this.isPassFormat) return "비밀번호를 확인해주세요.";
      if (!this.isPassContirm) return "비밀번호를 확인해주세요.";

      return null;
    },
    onClickPassEdit() {
      this.isPassEdit = !this.isPassEdit;

      if (!this.isPassEdit) {
        this.model.loginPass = "";
        this.loginPassConfirm = "";
      }
    },
    fileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    fileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },
  },
};
</script>
