<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">내 정보 수정</h2>
      </div>
      <div class="body_section">
        <MyInfo ref="myInfo" :model="model" @onClickMailConfirm="onClickMailConfirm" />
        <ManagerInfo :dataSync="model.managerInfo" :canAdd="true" />
      </div>
    </div>
    <Sticky>
      <button
        v-if="isReviewStatus"
        type="submit"
        class="btn_primary btn_large"
        @click="onClickConfirm"
      >
        검토요청
      </button>
      <button
        v-if="!isReviewStatus"
        type="button"
        class="btn_fourthly btn_large"
        @click="onClickCancel"
      >
        취소
      </button>
      <button
        v-if="!isReviewStatus"
        type="submit"
        class="btn_primary btn_large"
        @click="onClickConfirm"
      >
        수정완료
      </button>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import MyInfo from "./MyInfo";
import ManagerInfo from "@/components/shared/managerInput/ManagerInfo";
import Sticky from "@/components/layout/content/Sticky.vue";

import AccountEditModel from "./AccountEditModel";
import ApiService from "@/services/ApiService";

import LocalStorageManager from "@/LocalStorageManager";

import PageMixin from "@/mixins/PageMixin";

export default {
  name: "AccountEdit",
  components: {
    PageWithLayout,
    MyInfo,
    ManagerInfo,
    Sticky,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new AccountEditModel(),
    };
  },
  computed: {
    userNum() {
      const userData = LocalStorageManager.shared.getUserData();
      if (!userData) return "1";

      return userData.userNum || "1";
    },
    isReviewStatus() {
      const ret = LocalStorageManager.shared.getIsReviewStatus();
      return ret;
    },
  },
  mounted() {
    const path = `${this.$apiPath.PARTNER}/${this.userNum}`;
    this.getData(path);
  },
  methods: {
    async onClickMailConfirm() {
      var alertMess = null;

      if (!this.model.email.getIsValid()) alertMess = "메일 정보를 입력해주세요.";

      if (alertMess) {
        this.alert(alertMess);
        return;
      }

      const email = this.model.email.getTextFull();

      const path = `${this.$apiPath.MAIL_CONFIRM}?subject=파트너 가입 메일인증&to=${email}`;

      const result = await ApiService.shared.postData(path, {});

      this.alert(result.text);
    },
    async getData(path) {
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.alert(result.text);
        return;
      }

      this.model.setData(result.data);
    },
    onClickCancel() {
      this.goHome();
    },
    goHome() {
      this.$router.push(this.$routerPath.HOME);
    },
    onClickConfirm() {
      let alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      alertMessage = this.$refs.myInfo.getAlertMessageAboutPassword();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      const obj = this.model.getData();
      const path = `${this.$apiPath.PARTNER}/${this.userNum}`;
      this.saveData(path, obj);
    },
    async saveData(path, obj) {
      const result = await ApiService.shared.putData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      if (!this.isReviewStatus) {
        this.alert("다시 로그인해주시기 바랍니다.", () => {
          LocalStorageManager.shared.clear();
          window.location.href = this.$routerPath.LOGIN;
        });

        //this.goHome();

        return;
      }

      // alert 띄운다.
      this.alert("수정된 내용으로 담당자에게 검토 요청 메일이 발송되었습니다");
    },
  },
};
</script>
